import styled from 'styled-components';

const MultimediaMessageBubbleStyled = styled.li`
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  ${({ isThereErrorIcon }) => (isThereErrorIcon ? 'padding-right: 40px;' : '')}
  align-items: ${({ sendType }) => ((sendType === 'operator' || sendType === 'proactive' || sendType === 'agent_notification') ? 'flex-end' : 'flex-start')};

  .message-bubble-shape {
    padding: 3px 3px 0 !important;
  }
  .message-bubble-shape-operator {
    padding: 3px 3px 0 !important;
    max-width: 300px;
  }
  .message-bubble-shape-bot {
    padding: 3px 3px 0 !important;
    ${( interactiveButtons ) => (interactiveButtons ? 'margin-bottom: 8px;' : '')}
  }

  .message-bubble-shape.img-text-container {
    max-width: 300px;
    img {
      width: 100%;
    }
  }
  .message-bubble-shape-operator.img-text-container {
    max-width: 300px;
    img {
      width: 100%;
    }
  }
  .message-bubble-shape-bot.img-text-container {
    max-width: 300px;
    img {
      width: 100%;
    }
  }

  .error-icon-holder {
    position: absolute;
    top: 40px;
    right: 0;
    cursor: pointer;
    .info-icon {
      width: 28px;
      height: 28px;
      margin-left: 8px;
      margin-bottom: 4px;
    }
    .error {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
  .map-texts {
    display: flex;
    font-size: 14px;
    padding: 12px 12px 0 12px;
    color: #333333;
    width: 100%;
    flex-direction: column;
    align-items: felx-start;
    justify-content: flex-start;
    p {
      line-height: 14px;
    }
    .map-text-name {
      font-size: 0.8rem;
      color: #555555;
      text-transform: uppercase;
      font-weight: bold;
      padding-bottom: 5px;
    }
  }
  .contact-holder {
    min-width: 280px;
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
    min-height: 65px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.colors.grey[400]};
    .contact-content {
      transition: all .8s ease-in-out; 
      max-height:0;
    }
    .contact-item-wrapper {
      opacity: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      transition: opacity .8s ease-in-out; 
    }
    .open {
      &.contact-content {
        max-height:500px;
      }
      .contact-item-wrapper {
        opacity: 1;
      }
    }
    .contact-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .contact-arrow {
        transition: transform .8s ease-in-out; 
        transform-origin: center center;
        height: 24px;
      }
      .contact-name-birthday {
        transition: all .8s ease-in-out; 
        transform: translateY(10px);
      }
      .contact-birthday {
        opacity: 0;
        font-size: 0.8rem;
        color: #555555;
        transition: all .8s ease-in-out; 
        transform:translateY(10px);
      }
      &.border{
        border-bottom: 1px solid transparent;
        margin-bottom: 0px;
        transition: all .8s ease-in-out; 
      }
      &.open {
        .contact-arrow {
          transform:rotate(180deg); 
        }
        .contact-name-birthday {
          transform:translateY(0px);
        }
        .contact-birthday {
          opacity: 1;
          transform:translateY(0px);
        }
        &.border{
          border-bottom: 1px solid black;
          margin-bottom: 10px;
        }
      }
    }
    .contact-icon {
      margin-right: 10px;
    }
    .contact-header-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .contact-item-each {
      display: inline-block;
      padding-right: 8px;
      font-weight: 500;
    }
    .contact-item-type {
      font-size: 0.8rem;
      color: #555555;
    }
    .contact-org-title {
      font-weight: 500;
    }
  }

  .multimedia-holder {
    width: 294px;
    ${({ isError, isVideo, isAudio }) => {
      if (isError || isVideo) {
        return 'height: 120px;';
      }
      if (isAudio) {
        return 'height: 65px;';
      }
      return 'height: 250px;';
    }}
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: ${({ theme, isLocation }) => !isLocation && theme.colors.grey[400]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .rhap_container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      background-color: transparent;

      box-shadow: none;
      height: 65px;
      .rhap_controls-section {
        margin: 0;
        flex: none;
        .rhap_main-controls-button {
          color: ${({ theme }) => theme.colors.secondary.darker};
          margin-bottom: 10px;
        }
      }
      .rhap_progress-section {
        flex-direction: column-reverse;
        flex: 1;
        align-items: flex-start;

        .rhap_progress-container {
          order: 1;
          flex: 1;
          width: 100%;
          .rhap_progress-indicator {
            background-color: ${({ theme }) => theme.colors.primary.main};
            width: 12px;
            height: 12px;
            margin-left: 0px;
            top: -3px;
          }
        }
        .rhap_time {
          font-size: 10px;
        }
        .rhap_total-time {
          position: absolute;
          right: 15px;
        }
      }
      .rhap_additional-controls {
        display: none;
      }
      .rhap_volume-controls {
        display: none;
      }
    }

    .play-info-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    .error-icon {
      fill: ${({ theme }) => theme.colors.red};
      height: 40px;
      width: 40px;
    }

    .play-icon {
      fill: ${({ theme }) => theme.colors.secondary.darker};
      height: 50px;
      width: 50px;
    }

    .bubble-info-text {
      font-size: 11px;
      margin-top: 12px;
      font-style: italic;
    }

    ${({ isError, isAudio }) => {
      if (isError || isAudio) {
        return '';
      }

      return `
      &:hover {
          opacity: 0.8;
          cursor: pointer;
        }`;
    }}
  }
  .multimedia-holder-vert {
    width: 250;
    ${({ isError, isVideo, isAudio }) => {
      if (isError || isVideo) {
        return 'height: 120px;';
      }
      if (isAudio) {
        return 'height: 65px;';
      }
      return 'height: 294px;';
    }}
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .rhap_container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      background-color: transparent;

      box-shadow: none;
      height: 65px;
      .rhap_controls-section {
        margin: 0;
        flex: none;
        .rhap_main-controls-button {
          color: ${({ theme }) => theme.colors.secondary.darker};
          margin-bottom: 10px;
        }
      }
      .rhap_progress-section {
        flex-direction: column-reverse;
        flex: 1;
        align-items: flex-start;

        .rhap_progress-container {
          order: 1;
          flex: 1;
          width: 100%;
          .rhap_progress-indicator {
            background-color: ${({ theme }) => theme.colors.primary.main};
            width: 12px;
            height: 12px;
            margin-left: 0px;
            top: -3px;
          }
        }
        .rhap_time {
          font-size: 10px;
        }
        .rhap_total-time {
          position: absolute;
          right: 15px;
        }
      }
      .rhap_additional-controls {
        display: none;
      }
      .rhap_volume-controls {
        display: none;
      }
    }

    .play-info-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    .error-icon {
      fill: ${({ theme }) => theme.colors.red};
      height: 40px;
      width: 40px;
    }

    .play-icon {
      fill: ${({ theme }) => theme.colors.secondary.darker};
      height: 50px;
      width: 50px;
    }

    .bubble-info-text {
      font-size: 11px;
      margin-top: 12px;
      font-style: italic;
    }

    ${({ isError, isAudio }) => {
      if (isError || isAudio) {
        return '';
      }

      return `
      &:hover {
          opacity: 0.8;
          cursor: pointer;
        }`;
    }}
  }


  .img-text-holder {
    padding: 0 9px 0;

    p {
      margin: 0;
    }

    .message-caption {
      max-width: 294px !important;
    }

    .info-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .info-icon {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        margin-bottom: 4px;
        fill: gray;
      }
      .read {
        fill: ${({ theme }) => theme.colors.checkMarkBlue};
      }
    }
  }

  img {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 250px;
    object-fit: cover;
    object-position: center;
  }
  video {
    border-radius: 2px;
  }
  .interactive-holder {
    min-width: 280px;
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
    min-height: 65px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    .contact-content {
      transition: all .8s ease-in-out; 
      max-height:0;
    }
    .contact-item-wrapper {
      opacity: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      transition: opacity .8s ease-in-out; 
    }
    .open {
      &.contact-content {
        max-height:500px;
      }
      .contact-item-wrapper {
        opacity: 1;
      }
    }
    .interactive-wrapper {
      display: flex;
      font-size: 11px;
      width: 100%;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      .interactive-arrow {
        transition: transform .8s ease-in-out; 
        transform-origin: center center;
        height: 24px;
      }
      &.border{
        border-bottom: 1px solid transparent;
        margin-bottom: 0px;
        transition: all .8s ease-in-out; 
      }
    }
    .interactive-header-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 5px;
    }
    .interactive-body-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .interactive-body-footer {
      color: #8696a0;
    }
    .interactive-header-text {
      font-size: 15px;
      padding-right: 12px;
      color: #333333;
      font-weight: bold;
    }
    .interactive-body-text {
      font-size: 13px;
      padding-right: 12px;
      color: #333333;
      margin-bottom: 7px;
    }
    .interactive-footer-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .interactive-bottom-list {
      border-top: 1px solid black;
      width: 100%;
      padding: 6px 7px 0px 9px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 25px;

      .interactive-icon {
        margin-right: 10px;
      }

      .interactive-bottom-list-text {
        font-size: 14px;
      }

    }
  }
  .interactive-action-ctaUrl-container {
    margin-top: 10px;
    border-top: 1px solid #bbbbbb;
    padding: 5px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .interactive-action-ctaUrl {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .span-display-text-ctaUrl {
    margin-left: 5px;
    font-size: 13px;
  }
  .button-interactive {
    display: flex;
    cursor: default;
    margin-top: 8px;
    margin-bottom: 4px;
    padding: 6px 7px 8px 9px;
    color: black;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    height: 34px;
    font-size: 14px;
  
    background-color: #fde3a7;
    border-radius: 4px;
    line-height: 24px;
    -webkit-box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 20%);
  
  }
`;


export { MultimediaMessageBubbleStyled };
