import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { MessagesStyled } from './styled';
import { KeyboardDownArrowIcon, TransferConvIcon, CloseConvIcon, IconButton, BanUserIcon, TextArea } from '../../atoms';
import { Modal, ModalAttach, ModalTransfer, SendMessageInput, MessagesList, ModalPreview, ModalSF, ModalCloseForm, ModalTemplate, ModalConfirm } from '../../molecules';
import { useSocketContext } from '../../../sockets/context';
import { closeConversation, transferConversation, sendFormResponse, assignConvSelf, auditorUnsubscribeToConversation, getHistoricMessages } from '../../../sockets/emit';
import { withI18n } from '../../../hocs';
import bp from '../../../config/breakpoints';
import { MODAL_TYPE, USER_ROL } from '../../../models';
import { TemplateService, BanUserService, AuthService, TopicsService } from '../../../services';
import TagManager from '../../molecules/tag-manager/TagManager';

const locale = navigator.language;
const formatDateString = locale === 'es' ? 'DD-MM-YYYY HH:mm:ss' : 'YYYY-MM-DD hh:mm:ss A';

const MessagesEmpty = withI18n(({ i18n }) => (
  <div className="empty-messages-holder">
    <div className="empty-messages-card">
      <h2 className="empty-messages-title">{i18n.chats.emptyMessagesTitle}</h2>
      <p className="empty-messages-text">{i18n.chats.emptyMessagesText}</p>
    </div>
  </div>
));

const MessagesHeader = withI18n(({ onClose, onBan, onTransfer, selectedChat, chatLabel, i18n, hideToolTip }) => {
  const [canManage, setCanManage] = useState(false);
  const {
    topics,
    setTopics,
    auth,
    userInfo,
    conversations,
    setConversations,
  } = useSocketContext();

  useEffect(() => {
    let canCreateTags = false;
    const appId = auth?.applicationId;
    const roleUser = userInfo?.extraInfo?.roleType;
    if (roleUser === 'application_user') {
      if (userInfo?.extraInfo?.permissions?.[appId]?.role === 'MANAGE') {
        canCreateTags = true;
      }
    } else if (roleUser === 'account_admin' || roleUser === 'account_owner' || roleUser === 'super_admin') {
      canCreateTags = true;
    }
    setCanManage(canCreateTags);
  }, [auth, userInfo]);
  const selectedTopics = selectedChat?.topics;
  const saveSelectedTopics = (topicsToSave = []) => {
    const idSelectedConv = selectedChat?.conversationSessionId;
  
    if (idSelectedConv !== null && idSelectedConv !== undefined) {
      const updatedConversations = conversations.map((conversation) => 
        conversation.conversationSessionId === idSelectedConv 
          ? { ...conversation, topics: topicsToSave }
          : conversation
      );
      setConversations(updatedConversations);
    }
  };
  /* function getConsistentHexColor() {
    const min = 100;
    const max = 200;

    const r = Math.floor(Math.random() * (max - min + 1)) + min;
    const g = Math.floor(Math.random() * (max - min + 1)) + min;
    const b = Math.floor(Math.random() * (max - min + 1)) + min;

    const hexColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

    return hexColor;
  } */

  const createNewTopic = async (name) => {
    const code = name;
    const respCreatedTag = await TopicsService.createTopic(name, code);
    if (respCreatedTag?.ok) {
      const createdTag = respCreatedTag.data;
      setTopics([...topics, createdTag]);
      return createdTag;
    }
    return null;
  }
  const handleDelete = async (idTopic) => {
    const respDelete = await TopicsService.deleteTopic(idTopic);
    return respDelete;
  };
  const handleEditSave = async (editedTag) => {
    const updatedTag = await TopicsService.updateTopic(editedTag);
    return updatedTag;
  };
  const handleDeleteSelectedTags = async (tagsToDelete) => {
    await TopicsService.unsubscribeTopics(tagsToDelete, selectedChat?.conversationSessionId);
  };
  const handleAddSelectedTags = async (tagsToAdd) => {
    await TopicsService.subscribeTopics(tagsToAdd, selectedChat?.conversationSessionId);
  };
  return (
  <div className="messages-bar-holder">
    <p className="chat-title">
      {`${chatLabel}`}{' '}
      <span className="bar-small-text">{selectedChat.profileName && selectedChat.profileName !== '' ? `~${selectedChat.profileName}` : ''}</span>
    </p>
   <TagManager
        tags={topics}
        selectedTags={selectedTopics}
        setTags={setTopics}
        canEdit={canManage}
        setSelectedTags={saveSelectedTopics}
        onCreate={createNewTopic}
        onDelete={handleDelete}
        onEditSave={handleEditSave}
        onAddSelectedTags={handleAddSelectedTags}
        onDeleteSelectedTags={handleDeleteSelectedTags}
        createLabel={i18n.chats.createLabel}
        editLabel={i18n.chats.editLabel}
        deleteLabel={i18n.chats.deleteLabel}
        placeholderSelect={i18n.chats.placeholderSelect}
      />
    <IconButton
      onClick={onTransfer}
      disableToolTip={hideToolTip}
      toolTip={i18n.chats.transferConvToolTip}
      toolTipPosition="left"
      toolTipPositionOffset={10}
      className="transfer-btn"
    >
      <TransferConvIcon className="msg-bar-icon positive-action ts ts-normal" />
    </IconButton>

    <IconButton
      onClick={onBan}
      disableToolTip={hideToolTip}
      toolTip={i18n.chats.banUser}
      toolTipPosition="left"
      toolTipPositionOffset={10}
      className="transfer-btn"
    >
      <BanUserIcon className="msg-bar-icon negative-action ts ts-normal" />
    </IconButton>

    <IconButton
      onClick={onClose}
      disableToolTip={hideToolTip}
      toolTip={i18n.chats.endconvToolTip}
      toolTipPosition="left"
      toolTipPositionOffset={10}
      className="close-btn"
    >
      <CloseConvIcon className="msg-bar-icon negative-action ts ts-normal" />
    </IconButton>
  </div>
);
});


MessagesHeader.propTypes = {
  onBan: PropTypes.func,
  onClose: PropTypes.func,
  selectedChat: PropTypes.object,
  selectedChatId: PropTypes.number,
  i18n: PropTypes.object,
  hideToolTip: PropTypes.bool,
};

const Messages = ({ i18n }) => {
  const isMediumScreen = useMediaQuery({ query: `(max-width: ${bp.md}px)` });
  const auditor = AuthService.getUserRol() === USER_ROL.AUDITOR;
  const [showSFModal, setShowSFModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showCFModal, setShowCFModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showAttachModal, setShowAttachModal] = useState(false);
  const [attachModalAction, setAttachModalAction] = useState();
  const [multimediaModalAction, setMultimediaModalAction] = useState();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewSource, setPreviewSource] = useState();
  const [showScrollToBottomBtn, setShowScrollToBottomBtn] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [listTemplates, setListTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(false);
  const [showConfirmBanUserModal, setShowConfirmBanUserModal] = useState(false);
  const [banReason, setBanReason] = useState('');
  const [showOnlyButtonList, setShowOnlyButtonList] = useState([]);
  const [auditorTemplate, setAuditorTemplate] = useState(null);
  const [disabledTemplateSending, setDisabledTemplateSending] = useState(false);

  const {
    conversations,
    selectedChatId,
    formClose,
    setFormClose,
    removeConversation,
    scrollAllowed,
    setScrollAllowed,
    agents,
    crmExportConversationEnabled,
    setCountUnread,
    addBannedUser,
    setGlobalState,
  } = useSocketContext();

  const updateAuditorTemplate = async () => {
    const resp = await TemplateService.getAuditorTemplate();
    if (resp && resp.length && resp[0]) {
      setAuditorTemplate(resp[0]);
    }
  }
  const cleanOnlyButtonList = (convs) => {
    let copyOnlyButtonList = [...showOnlyButtonList];
    copyOnlyButtonList = copyOnlyButtonList.filter((cItem) => {
      return convs.find((aItem) => {
        return cItem === aItem.conversationSessionId
      })
    })
    setShowOnlyButtonList(copyOnlyButtonList);
  };

  useEffect(() => {
    cleanOnlyButtonList(conversations);
  }, [conversations])

  const messagesEndRef = useRef();
  const scrollElementRef = useRef();
  const scrollToBottom = (options = {}) => {
    if (scrollAllowed.isAllowed) {
      if (messagesEndRef && messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView(options);
        // setScrollAllowed(false, {});
      }
    }
  };

  useEffect(() => {
    if (scrollAllowed.isAllowed) {
      scrollToBottom(scrollAllowed.options);
    }
  }, [scrollAllowed]);
  useEffect(() => {
    if (!!formClose !== !!showCFModal) {
      setShowCFModal(!!formClose)
    }
  }, [formClose, showCFModal]);
  useEffect(() => {
    const handleVisibilityChange = (evt) => {
      if (!evt.target.hidden) {
        setCountUnread(0);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [setCountUnread]);

  const handleScrollToBottomClick = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScrollToPosSelected = (posSelected) => {
    const element = document.getElementById(`msg-${posSelected}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const isChats = conversations.length > 0;
  let selectedChat = {};
  
  if (isChats) {
    const filteredChats = conversations.filter((chat) => chat.conversationSessionId === selectedChatId);
    [selectedChat] = filteredChats;
  }
  const lastMessageId = selectedChat && selectedChat.messages && selectedChat.messages.length &&
    selectedChat.messages[0] && selectedChat.messages[0].messageId;
  const getMoreMessages = async (idConv, lastId) => {
    await getHistoricMessages(idConv, lastId);
    handleScrollToPosSelected(lastId);
  };
  const handleScroll = () => {
    const { scrollTop, offsetHeight, scrollHeight } = scrollElementRef.current;
    const isScrolledToBottom = scrollHeight - offsetHeight === Math.floor(scrollTop);
    const isScrolledToBottomOffset = scrollHeight - offsetHeight - (scrollHeight - offsetHeight) * 0.28 < scrollTop;
    if (!isScrolledToBottomOffset) {
      setShowScrollToBottomBtn(true);
    }
    if (isScrolledToBottom) {
      setShowScrollToBottomBtn(false);
    }
  };
  useEffect(() => {
    updateAuditorTemplate()
  }, [])
  useEffect(() => {
    const theresContactId = conversations?.find((el) => el?.conversationSessionId === selectedChatId) && conversations?.find(el => el?.conversationSessionId === selectedChatId)?.contactId;
    setDisabledTemplateSending(!theresContactId);
  }, [conversations, selectedChatId])
  const sendTemplate = async (template) => {
    const toWho = conversations.find(el => el.conversationSessionId === selectedChatId) && conversations?.find(el => el.conversationSessionId === selectedChatId).contactId;
    const { channel } = selectedChat;
    if (auditorTemplate) {
      TemplateService.sendTemplate(template, toWho, channel);
    }
  
  }
  const handleModalCancel = () => {
    if (showCloseModal) setShowCloseModal(false);
    if (showSFModal) setShowSFModal(false);
    if (showCFModal) setShowCFModal(false);
    if (showTransferModal) setShowTransferModal(false);
    if (showAttachModal) setShowAttachModal(false);
    if (showPreviewModal) setShowPreviewModal(false);
  };

  const handleModalFinish = () => {
    closeConversation(selectedChatId);
    removeConversation(selectedChatId);
    setShowCloseModal(false);
  };
  /* const hasAuditorStartedChat = (selChatId) => {
    return showOnlyButtonList.includes(selChatId);
  } */
  const handleUnsubscribeConversation = () => {
    /* closeConversation(selectedChatId); */
    removeConversation(selectedChatId);
    auditorUnsubscribeToConversation(selectedChatId);
    closeConversation(selectedChatId);
    /* if (hasAuditorStartedChat(selectedChatId)) {
      closeConversation(selectedChatId);
    } */

  };
  const assignConvToMyself = () => {
    assignConvSelf(selectedChatId);
    setShowOnlyButtonList(ids => [...ids, selectedChatId]);
  };
  const saveSFFormAndCloseConv = (crmCase) => {
    closeConversation(selectedChatId, crmCase);
    removeConversation(selectedChatId);
    setShowSFModal(false);
  };
  const saveCloseModalForm = (formToSave) => {
    sendFormResponse(formToSave);
    setFormClose(false);
  };
  const handleModalTransfer = (agentId) => {
    transferConversation(selectedChatId, agentId);
    setShowTransferModal(false);
  };
  const toggleSFModal = () => setShowSFModal(!showSFModal);
  const toggleCloseModal = () => setShowCloseModal(!showCloseModal);
  const toggleTransferModal = () => setShowTransferModal(!showTransferModal);
  const closeModalSFOpenCloseModal = () => {
    handleModalCancel();
    toggleCloseModal();
  };
  const closeModalCF = () => {
    handleModalCancel();
  }
  const handleAttachEvent = (action) => {
    setAttachModalAction(action);
    setShowAttachModal(true);
  };

  const handleMultimediaClick = (action, source) => {
    setMultimediaModalAction(action);
    setPreviewSource(source);
    setShowPreviewModal(true);
  };

  const banUser = (reason) => {
    setShowConfirmBanUserModal(false);
    BanUserService.ban([{ conversationSessionId: selectedChatId, reason }])
      .then(({ data: { data } }) => {
        if (data[0]) {
          addBannedUser(data[0]);
        }
        toast(i18n.chats.bannedSuccesfully, { type: toast.TYPE.SUCCESS });
      })
      .catch(() => toast(i18n.errors.banUser, { type: toast.TYPE.ERROR }));
    setBanReason('');
  };

  useEffect(() => {
    const loadTemplates = async () => {
      TemplateService.getList()
        .then((templates) => setListTemplates(templates))
        .catch(() => toast(i18n.templateSelector.errorLoadingTemplates, { type: toast.TYPE.ERROR }));
    };
    loadTemplates();
  }, [i18n.templateSelector.errorLoadingTemplates]);

  const handleAddTemplate = () => {
    setShowTemplateModal(true);
  };

  const createTemplate = (template) => {
    TemplateService.create(template)
      .then((newTemplate) => {
        setListTemplates((currentList) => [...currentList, { ...newTemplate }]);
        toast(i18n.templateSelector.createdTemplate, { type: toast.TYPE.SUCCESS });
      })
      .catch(() => {
        toast(i18n.templateSelector.errorCreatingTemplate, { type: toast.TYPE.ERROR });
      });
    setShowTemplateModal(false);
  };

  const deleteTemplate = (templateId) => {
    setSelectedTemplateId(null);
    TemplateService.delete(templateId)
      .then(() => {
        setListTemplates((currentList) => currentList.filter((template) => template.id !== templateId));
        toast(i18n.templateSelector.deletedTemplate);
      })
      .catch(() => toast(i18n.templateSelector.errorDeletingTemplate, { type: toast.TYPE.ERROR }));
  };
  const conv = conversations?.find(el => el.conversationSessionId === selectedChatId);
  const isContactId = conv?.contactId && (conv?.channel === 'whatsapp'  || conv?.channel === 'cloudapi');
  const chatLabel = isContactId ? `${i18n.chats.contactId}: +${conv.contactId}` : `${i18n.chats.singleConversation} #${selectedChatId}`;
  
  let onCloseFunc = crmExportConversationEnabled ? toggleSFModal : toggleCloseModal;
  if (auditor) {
    onCloseFunc = handleUnsubscribeConversation;
  }
  return (
    <MessagesStyled showScrollToBottomBtn={showScrollToBottomBtn}>
      {selectedChatId !== -1 && !_.isEmpty(conversations) ? (
        <>
          <MessagesHeader
            onClose={onCloseFunc}
            onTransfer={toggleTransferModal}
            onBan={() => setShowConfirmBanUserModal(true)}
            chatLabel={chatLabel}
            selectedChat={selectedChat}
            selectedChatId={selectedChatId}
            hideToolTip={isMediumScreen}
          />
          <div className="messages-list-container">
            <div ref={scrollElementRef} className="messages-list-holder" onScroll={handleScroll}>
              {auditor && <button type="button" className="button-load-more-messages" onClick={() => getMoreMessages(selectedChatId, lastMessageId)}>{i18n.chats.showMoreMessages}</button>}
              <MessagesList onMultimediaClick={handleMultimediaClick} chat={selectedChat} formatDateString={formatDateString} />
              <div ref={messagesEndRef} />
            </div>
            <div
              data-tip={i18n.chats.scrollToBotToolTip}
              data-place="left"
              data-delay-show={1000}
              data-tip-disable={isMediumScreen}
              data-offset="{'left':10}"
              className="message-list-scroll-to-bottom-btn ts ts-slow"
              onClick={handleScrollToBottomClick}
            >
              <KeyboardDownArrowIcon className="message-list-scroll-to-bottom-btn-icon ts ts-slow" />
            </div>
          </div>
          <SendMessageInput
            templateList={listTemplates}
            onAttachEvent={handleAttachEvent}
            onAddTemplate={handleAddTemplate}
            onDeleteTemplate={setSelectedTemplateId}
            sendTemplate={sendTemplate}
            assignConvToMyself={assignConvToMyself}
            isAuditor={!!auditor}
            auditorTemplate={auditorTemplate}
            showOnlyButton={auditor && !showOnlyButtonList.includes(selectedChatId)}
            setGlobalState={setGlobalState}
            conversations={conversations}
            setScrollAllowed={setScrollAllowed}
            disabledTemplateSending={disabledTemplateSending}
          />
          <Modal action={MODAL_TYPE.CLOSE_CONV} show={showCloseModal} onModalCancel={handleModalCancel} onModalFinish={handleModalFinish} />
          <ModalTransfer
            action={MODAL_TYPE.TRANSFER_CONV}
            show={showTransferModal}
            agents={agents}
            onModalCancel={handleModalCancel}
            onModalFinish={handleModalTransfer}
          />
          <ModalAttach action={attachModalAction} show={showAttachModal} onModalCancel={handleModalCancel} />
          <ModalPreview
            action={multimediaModalAction}
            show={showPreviewModal}
            cancelLabel={i18n.buttons.cancel}
            source={previewSource}
            onModalCancel={handleModalCancel}
          />
          <ModalSF show={showSFModal} onModalCancel={closeModalSFOpenCloseModal} action={saveSFFormAndCloseConv} />
          <ModalTemplate
            title={i18n.templateModal.createTitle}
            show={showTemplateModal}
            onSubmit={createTemplate}
            onCancel={() => setShowTemplateModal(false)}
          />
          <ModalConfirm
            show={showConfirmBanUserModal}
            title={i18n.chats.banConversation}
            body={
              <div>
                <p>{`${i18n.chats.areYouSureBanUserId} ${isContactId ? `+${conv.contactId}` : `#${selectedChatId}`} ${i18n.chats.infoBanUser}`}</p>
                <p className="grey-p">{i18n.chats.reasonBlocking}</p>
                <TextArea value={banReason} placeholder={i18n.chats.reason} onChangeValue={(val) => setBanReason(val)} />
              </div>
            }
            actionLabel={i18n.chats.ban}
            onModalDoAction={() => banUser(banReason)}
            cancelLabel={i18n.buttons.cancel}
            onModalCancel={() => setShowConfirmBanUserModal(false)}
          />
          <ModalConfirm
            show={!!(selectedTemplateId || selectedTemplateId === 0)}
            title={i18n.templateModal.deleteTemplate}
            body={i18n.templateModal.areYouSureDelete}
            actionLabel={i18n.templateModal.deleteTitle}
            onModalDoAction={() => deleteTemplate(selectedTemplateId)}
            cancelLabel={i18n.buttons.cancel}
            onModalCancel={() => setSelectedTemplateId(null)}
          />
          <ReactTooltip type="info" />
        </>
      ) : (
        <MessagesEmpty />
      )}
      <ModalCloseForm show={showCFModal} formFromState={formClose} onModalCancel={closeModalCF} action={saveCloseModalForm} />
    </MessagesStyled>
  );
};

Messages.propTypes = {
  i18n: PropTypes.object,
};

export default withI18n(Messages);
