/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { TemplateMessageBubbleStyled } from './styled';
import i18n from '../../../assets/i18n';
import { replacePlaceholders } from './utils';
import { InteractiveExtraContent } from './InteractiveExtraContent';
import MultimediaContentTemplate from './multimediaContent-template';

const getTemplateComponentFromContent = (content, elementName) => {
  return content.templateComponents.find(el => el.type === elementName);
}
const getVariablesFromContent = (content, elementName) => {
  return content.components.find(el => el.type === elementName);
}

const TemplateMessageBubble = ({ colorClass, date, sendType, statusIcon, errorIcon, content, onMultimediaClick }) => {
  const paintHeader = (_content) => {
    const headerObjectToReplace = getTemplateComponentFromContent(_content, 'HEADER');
    if (!headerObjectToReplace) {
      return <div />;
    }
    const variablesToReplace = getVariablesFromContent(_content, 'header');
    let contentHeader;
    if (headerObjectToReplace?.format === 'TEXT') {
      contentHeader = (
        <div className="header-template-container">
          <p className="header-template-text">
            {replacePlaceholders(headerObjectToReplace, variablesToReplace)}
          </p>
        </div>
      );
    }
    if (headerObjectToReplace?.format === 'IMAGE') {
      contentHeader = (
        <div>
          <MultimediaContentTemplate
            multimediaId={variablesToReplace?.parameters.length && variablesToReplace.parameters[0]?.multimediaId}
            onMultimediaClick={onMultimediaClick}
            image
          />
        </div>
      );
    }
    if (headerObjectToReplace?.format === 'VIDEO') {
      contentHeader = (
        <div>
          <MultimediaContentTemplate
            multimediaId={variablesToReplace?.parameters.length && variablesToReplace.parameters[0]?.multimediaId}
            onMultimediaClick={onMultimediaClick}
            video
          />
        </div>
      );
    }
    if (headerObjectToReplace?.format === 'DOCUMENT') {
      contentHeader = (
        <div>
          <MultimediaContentTemplate
            multimediaId={variablesToReplace?.parameters.length && variablesToReplace.parameters[0]?.multimediaId}
            onMultimediaClick={onMultimediaClick}
            document
          />
        </div>
      );
    }
    if (headerObjectToReplace?.format === 'LOCATION') {
      const locationParameters = _content.components[0].parameters[0].location;
      contentHeader = (
        <div>
          <MultimediaContentTemplate
            locationParameters={locationParameters}
            onMultimediaClick={onMultimediaClick}
            location
          />
        </div>
      );
    }
    const jsxHeader = (
      <div>
        {contentHeader}
      </div>
    );
    return jsxHeader;

  };
  const paintBody = (_content) => {
    const bodyObjectToReplace = getTemplateComponentFromContent(_content, 'BODY');
    const variablesToReplace = getVariablesFromContent(_content, 'body');
    const message = replacePlaceholders(bodyObjectToReplace, variablesToReplace);
    const regExpressionForStrong = /\*(.*?)\*/g;
    const regExpressionForEm = /\_(.*?)\_/g;
    const regExpressionForScratch = /\~(.*?)\~/g;

    let formattedHtml = message.replace(/\n/g, '<br>');

    const isBold = regExpressionForStrong.test(message);
    const isItalic = regExpressionForEm.test(message);
    const isScratched = regExpressionForScratch.test(message);
  
    if (isBold) {
      formattedHtml = formattedHtml.replace(regExpressionForStrong, "<strong>$1</strong>");
    }
    if (isItalic) {
      formattedHtml = formattedHtml.replace(regExpressionForEm, "<em>$1</em>");
    }
    if (isScratched) {
      formattedHtml = formattedHtml.replace(regExpressionForScratch, "<del>$1</del>");
    }

    return <div className="body-template" dangerouslySetInnerHTML={{ __html: formattedHtml }} />;
  };
  const paintFooter = (_content) => {
    const footerObjectToReplace = getTemplateComponentFromContent(_content, 'FOOTER');
    if (!footerObjectToReplace) {
      return <div />;
    }
    /* const variablesToReplace = getVariablesFromContent(content, 'FOOTER'); */

    let contentFooter;
    if (footerObjectToReplace?.text) {
      contentFooter = (
        <p className="footer-template">
          {footerObjectToReplace?.text}
        </p>
      );
    }
    const jsxFooter = (
      <div>
        {contentFooter}
      </div>
    );
    return jsxFooter;
  };
  const paintTemplate = (_content) => {
    const headerPart = paintHeader(_content);
    const bodyPart = paintBody(_content);
    const footerPart = paintFooter(_content);
    const jsxToReturn = (
      <div className="container-template">
        {headerPart}
        {bodyPart}
        {footerPart}
      </div>
    );
    return jsxToReturn;
  };
  let templateJsx = <p className="deleted-text">{`${i18n.chats.template} ${content.name} ${i18n.chats.sent}`}</p>
  let buttonsJsx;
  let variablesButtons;
  if (content?.templateComponents?.length) {
    templateJsx = paintTemplate(content);
    buttonsJsx = getTemplateComponentFromContent(content, 'BUTTONS');
    variablesButtons = getVariablesFromContent(content, 'buttons');
  }

  return (
    <div className="prueba">
      <TemplateMessageBubbleStyled
        /* ref={ref} */
        sendType={sendType}
        /* isError={isDownloadError}
        isVideo={video}
        isAudio={audio}
        isLocation={location}
        isContact={contacts} */
        interactiveButtons={buttonsJsx}
        isThereErrorIcon={!_.isNil(errorIcon)}
      >
        {errorIcon && (
          <div className="error-icon-holder" data-tip={i18n.errors.tooltipError} data-offset="{'left': 5}" /* data-tip-disable={isMediumScreen} */>
            {errorIcon}
          </div>
        )}
        <div className={`${colorClass} template-container`}>
          <div className="img-text-holder">
            <div className="message-caption">{templateJsx}</div>
            <div className="info-row">
              <p className="timespan-message">{date}</p>
              {statusIcon}
            </div>
          </div>
        </div>
        <ReactTooltip type="info" />
        {buttonsJsx &&
          <InteractiveExtraContent
            buttonsContent={buttonsJsx}
            buttonsVariables={variablesButtons}
          />
        }
      </TemplateMessageBubbleStyled>
    </div>
  );
}

TemplateMessageBubble.propTypes = {
  colorClass: PropTypes.string,
  date: PropTypes.string,
  sendType: PropTypes.string,
  statusIcon: PropTypes.any,
  errorIcon: PropTypes.any,
  content: PropTypes.object,
  onMultimediaClick: PropTypes.func,
};

export default TemplateMessageBubble;
